
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonSpinner,
    IonIcon,
    IonSelect,
    IonSelectOption,
    modalController,
    //menuController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

import { refresh, arrowBackOutline } from "ionicons/icons";

import { StreamBarcodeReader } from "vue-barcode-reader";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiMagazzino from "@/services/magazzino";
import apiProdotti from "@/services/prodotti";

//import Barcode from "@/components/Barcode/Barcode.vue";

export default defineComponent({
    name: "ModalNuovoMovimento",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
        IonSelect,
        IonSelectOption,
        StreamBarcodeReader,
        //Barcode,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
        const userId = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const loading = ref(false);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /*  const openMenu = () => {
            menuController.open("app-menu");
        }; */

        const magazzini = ref([]);
        async function loadMagazzini() {
            loading.value = true;
            try {
                const res = await apiMagazzino.getMagazzini();
                if (res.status === 200 && res.data.status === 0) {
                    magazzini.value = res.data.data;
                }
            } catch (error) {
                console.log(error);
                openToast("Errore durante la richiesta dei magazzini", "toast_danger");
            } finally {
                loading.value = false;
            }
        }
        loadMagazzini();

        /**
         * ! CAUSALI DOCUMENTO IN BASE AL TIPO SCELTO
         */
        const causali = ref([]);
        async function loadCausali(tipo_movimento: string) {
            try {
                const res = await apiMagazzino.getCausali(tipo_movimento);
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    causali.value = res.data.data;
                }
            } catch (error) {
                console.log(error);
                openToast("Errore durante la richiesta delle causali", "toast_danger");
            }
        }

        /**
         * ! Handle magazzino selection
         */
        const selectedMagazzino = ref(null);
        function setSelectedMagazzino(magazzino) {
            //console.log(magazzino);
            selectedMagazzino.value = magazzino;
            console.log(selectedMagazzino.value);
        }
        /**
         * ! Handle magazzino selection
         */
        const selectedMovimento = ref(null);
        function setSelectedMovimento(movimento) {
            //console.log(movimento);
            selectedMovimento.value = movimento;
            //console.log(selectedMovimento.value);
            //Richiesta causali corrette
            loadCausali(selectedMovimento.value);
        }
        const selectedCausale = ref(null);
        function setSelectedCausale(causale) {
            //console.log(magazzino);
            selectedCausale.value = causale;
            console.log(selectedCausale.value);
        }

        /**
         * ! Barcode & QR reader
         */
        const products = ref([]);
        const scannerRef = ref(null);
        const readingBarcode = ref(false);
        const decodedText = ref("");

        /**
         * Riabilito manualmente la scansione dopo l'aggiunta di un prodotto
         */
        const reloadScanning = () => {
            if (scannerRef.value) {
                scannerRef.value.codeReader.stream.getTracks().forEach((track) => {
                    //console.log(track);
                    track.enabled = true;
                });
            }
        };

        const onLoaded = () => {
            console.log("loaded");
        };

        const disableNewMovimento = ref(false);
        let timeout = null;
        const onDecode = async (text) => {
            decodedText.value = text;
            // Leggo barcode e faccio chiamata per trovare il prodotto, mostrare titolo ed aggungerlo
            // disabilito temporaneamente tutte le tracce, le posso riabilitare manualmente dopo la chiamata
            scannerRef.value.codeReader.stream.getTracks().forEach((track) => {
                //track.stop();
                //console.log(track);
                track.enabled = false;
            });

            const scannedBarcode = text;
            disableNewMovimento.value = false;

            try {
                const res = await apiProdotti.searchProductByBarcode(scannedBarcode);

                if (res.status === 200 && res.data.status === 0) {
                    if (res.data.data.length === 0) {
                        openToast("Non è stato rilevato alcun prodotto con il barcode letto", "toast_danger");
                        return;
                    } else {
                        let barcode = null;

                        if (res.data.data[0].fw_products_barcode) {
                            const parse = JSON.parse(res.data.data[0].fw_products_barcode);
                            barcode = parse.join(", ");
                        }
                        products.value = [
                            {
                                name: res.data.data[0].fw_products_name,
                                barcode: barcode,
                                product: res.data.data[0],
                            },
                            ...products.value,
                        ];
                        // Reload scan dopo 3 secondi
                        timeout = setTimeout(reloadScanning, 3000);
                    }
                }
                //console.log(products.value);
            } catch (error) {
                console.log(error);
                openToast("si è verificato un errore durante la lettura del barcode", "toast_danger");
            } finally {
                disableNewMovimento.value = false;
            }
        };

        /**
         *
         * ! CREAZIONE MOVIMENTO
         *
         */
        const loadingCreaMovimento = ref(false);
        async function creaMovimento() {
            if (!selectedMagazzino.value) {
                openToast("Devi selezionare il magazzino in cui effettuare il movimento", "toast_danger");
                return;
            }
            if (!selectedMovimento.value) {
                openToast("Devi selezionare il tipo di movimento da effettuare", "toast_danger");
                return;
            }
            if (products.value.length === 0) {
                openToast("Non puoi creare il movimento senza aver aggiunto almeno un prodotto", "toast_danger");
                return;
            }

            loadingCreaMovimento.value = true;

            try {
                const res = await apiMagazzino.saveMovimento(selectedMagazzino.value, selectedMovimento.value, selectedCausale.value, userId, products.value);

                if (res.data.status === 0) {
                    openToast(`${res.data.txt}`, "toast_danger");
                } else {
                    openToast(`${res.data.txt}`, "toast_success");
                }
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante la creazione del movimento", "toast_danger");
            } finally {
                loadingCreaMovimento.value = false;
            }
        }

        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loading,
            arrowBackOutline,
            //openMenu,
            closeModal,
            //Magazzini
            magazzini,
            setSelectedMagazzino,
            selectedMagazzino,
            setSelectedMovimento,
            selectedMovimento,
            //QR & Barcode
            onLoaded,
            onDecode,
            decodedText,
            scannerRef,
            reloadScanning,
            products,
            creaMovimento,
            causali,
            setSelectedCausale,
            selectedCausale,
            disableNewMovimento,
            loadMagazzini,
        };
    },
});
